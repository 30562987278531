import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-5-tablet is-5-desktop is-4-widescreen">
            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
              NOT FOUND
            </h2>
            <p>You just hit a route that doesn&#39;t exist...</p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
